import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  fontSize,
  headingStyles,
  visuallyHidden,
} from '../styles';
import { Container } from './ui';

const StyledLocationCards = styled.section`
  ${sectionMargins('30px', '70px')};
`;

const StyledHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tiny`
    row-gap: 25px;
  `}

  ${minBreakpointQuery.small`
    row-gap: 30px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    row-gap: 35px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    row-gap: 40px;
  `}
`;

const StyledItem = styled.article`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  color: ${brandColours.quaternary};
  background-color: ${brandColours.primary};
  border-radius: 15px;

  ${minBreakpointQuery.small`
    padding: 35px;
  `}

  ${minBreakpointQuery.large`
    padding: 40px;
  `}
`;

const StyledName = styled.h3`
  ${headingStyles()};
`;

const StyledDetails = styled.address`
  margin-top: auto;
  font-style: normal;
`;

const StyledAddress = styled.p`
  ${fontSize(16)};
  line-height: 1.5;
  white-space: break-spaces;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledPhoneNumber = styled.a`
  margin-top: 5px;
  font-weight: ${fontWeights.bold};
  ${fontSize(16)};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledMapLink = styled.a`
  display: block;
  margin-top: 12px;
  font-weight: ${fontWeights.bold};
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    margin-top: 16px;
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
    ${fontSize(16)};
  `}

  &:after {
    content: '→';
    margin-left: 8px;
    ${fontSize(18)};

    ${minBreakpointQuery.small`
      margin-left: 10px;
      ${fontSize(20)};
    `}

    ${minBreakpointQuery.large`
      margin-left: 12px;
      ${fontSize(22)};
    `}
  }
`;

const LocationCards = ({ items }) => (
  <StyledLocationCards>
    <Container>
      <StyledHeading>Locations</StyledHeading>
      <StyledItems>
        {items.map(({ id, name, address, phoneNumber }) => (
          <StyledItem key={id}>
            <StyledName>{name}</StyledName>
            <StyledDetails>
              <StyledAddress>{address}</StyledAddress>
              <StyledPhoneNumber href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </StyledPhoneNumber>
              {address && (
                <StyledMapLink
                  href={`https://www.google.co.uk/maps?q=Diversity+Travel+${name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Google Maps
                </StyledMapLink>
              )}
            </StyledDetails>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledLocationCards>
);

export default LocationCards;
