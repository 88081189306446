import React from 'react';
import { graphql } from 'gatsby';
import Accordion from './Accordion';
import AnchoredContentBlocks from './AnchoredContentBlocks';
import CtaStrip from './CtaStrip';
import ContactCtaStrip from './ContactCtaStrip';
import ContainedImage from './ContainedImage';
import ContentBlocks from './ContentBlocks';
import Content from './Content';
import ContentFeaturedLinksImageLinkCards from './ContentFeaturedLinksImageLinkCards';
import DiversityTravelApp from './DiversityTravelApp';
import EnquiryForm from './EnquiryForm';
import ExternalVideo from './ExternalVideo';
import FeaturedLogos from './FeaturedLogos';
import FeaturedResources from './FeaturedResources';
import FeaturedTeam from './FeaturedTeam';
import FeaturedTestimonials from './FeaturedTestimonials';
import FullWidthImage from './FullWidthImage';
import IconContentBlocks from './IconContentBlocks';
import ImageContent from './ImageContent';
import ImageCtaCards from './ImageCtaCards';
import ImageGallery from './ImageGallery';
import ImageLinkCards from './ImageLinkCards';
import InternalVideo from './InternalVideo';
import LinkedinContactForm from './LinkedinContactForm';
import LocationCards from './LocationCards';
import LogoContentBlocks from './LogoContentBlocks';
import NewClientForm from './NewClientForm';
import ReservationsForm from './ReservationsForm';
import Tabs from './Tabs';
import TeamListing from './TeamListing';
import TravelTechnologyForm from './TravelTechnologyForm';
import VideoPlayer from './VideoPlayer';
import VisaWidget from './VisaWidget';

const ModularBlocks = ({ items, locationData, reduceGaps, locale }) =>
  items.map(item => (
    <React.Fragment key={item.id}>
      {item.model.apiKey === 'accordion_modular_block' && (
        <Accordion heading={item.heading} items={item.accordionItems} />
      )}
      {item.model.apiKey === 'anchored_content_blocks_modular_block' && (
        <AnchoredContentBlocks
          heading={item.heading}
          text={item.text}
          items={item.anchoredContentBlocks}
        />
      )}
      {item.model.apiKey === 'cta_strip_modular_block' && (
        <CtaStrip
          heading={item.heading}
          link={item.link}
          version={item.version}
        />
      )}
      {item.model.apiKey === 'contact_cta_strip_modular_block' && (
        <ContactCtaStrip
          heading={item.heading}
          text={item.text}
          phoneNumber={item.phoneNumber}
          emailAddress={item.emailAddress}
        />
      )}
      {item.model.apiKey === 'contact_form_modular_block' && (
        <TravelTechnologyForm
          heading={item.heading}
          text={item.text}
          locale={locale}
          reduceGaps={reduceGaps}
        />
      )}
      {item.model.apiKey === 'contained_image_modular_block' && (
        <ContainedImage image={item.image} reduceGaps={reduceGaps} />
      )}
      {item.model.apiKey === 'content_blocks_modular_block' && (
        <ContentBlocks
          heading={item.heading}
          items={item.contentBlocks}
          link={item.link}
        />
      )}
      {item.model.apiKey === 'content_modular_block' && (
        <Content
          html={item.content}
          twoColumns={item.twoColumns}
          backgroundColour={item.backgroundColour && item.backgroundColour.hex}
          reduceGaps={reduceGaps}
        />
      )}
      {item.model.apiKey ===
        'content_featured_links_image_link_cards_modular_block' && (
        <ContentFeaturedLinksImageLinkCards
          heading={item.heading}
          text={item.text}
          link={item.link}
          featuredLinksHeading={item.featuredLinksHeading}
          featuredLinks={item.featuredLinks}
          imageLinkCards={item.imageLinkCards}
        />
      )}
      {item.model.apiKey === 'diversity_travel_app_modular_block' && (
        <DiversityTravelApp
          heading={item.heading}
          text={item.text}
          appStoreUrl={item.appStoreUrl}
          googlePlayUrl={item.googlePlayUrl}
          items={item.travelItems}
          image={item.tempImage}
        />
      )}
      {item.model.apiKey === 'external_video_modular_block' && (
        <ExternalVideo video={item.externalVideo} reduceGaps={reduceGaps} />
      )}
      {item.model.apiKey === 'featured_logos_modular_block' && (
        <FeaturedLogos heading={item.heading} logos={item.logos} />
      )}
      {item.model.apiKey === 'featured_resources_modular_block' && (
        <FeaturedResources heading={item.heading} />
      )}
      {item.model.apiKey === 'featured_team_modular_block' && (
        <FeaturedTeam
          heading={item.heading}
          link={item.link}
          items={item.team}
        />
      )}
      {item.model.apiKey === 'featured_testimonials_modular_block' && (
        <FeaturedTestimonials
          heading={item.heading}
          items={item.testimonials}
        />
      )}
      {item.model.apiKey === 'form_modular_block' &&
        (item.formName === 'New Client' ? (
          <NewClientForm
            heading={item.heading}
            text={item.text}
            locale={locale}
          />
        ) : item.formName === 'Travel Technology' ? (
          <TravelTechnologyForm
            heading={item.heading}
            text={item.text}
            locale={locale}
          />
        ) : item.formName === 'Reservations' ? (
          <ReservationsForm
            heading={item.heading}
            text={item.text}
            locale={locale}
          />
        ) : item.formName.includes('Linkedin') ? (
          <LinkedinContactForm
            locationData={locationData}
            heading={item.heading}
            text={item.text}
            locale={locale}
            isEU={item.formName.includes('EU')}
          />
        ) : (
          <EnquiryForm
            heading={item.heading}
            text={item.text}
            locale={locale}
          />
        ))}
      {item.model.apiKey === 'full_width_image_modular_block' && (
        <FullWidthImage image={item.image} reduceGaps={reduceGaps} />
      )}
      {item.model.apiKey === 'icon_content_blocks_modular_block' && (
        <IconContentBlocks
          heading={item.heading}
          items={item.iconContentBlocks}
        />
      )}
      {item.model.apiKey === 'image_content_modular_block' && (
        <ImageContent
          image={item.image}
          heading={item.heading}
          text={item.text}
          featuredPoints={item.featuredPoints}
          featuredLinks={item.featuredLinks}
          link={item.link}
          imageBlobMask={item.imageBlobMask}
          blobColour={item.blobColour && item.blobColour.hex}
          flip={item.flip}
        />
      )}
      {item.model.apiKey === 'image_cta_cards_modular_block' && (
        <ImageCtaCards heading={item.heading} items={item.imageCtaCards} />
      )}
      {item.model.apiKey === 'image_gallery_modular_block' && (
        <ImageGallery heading={item.heading} items={item.images} />
      )}
      {item.model.apiKey === 'image_link_cards_modular_block' && (
        <ImageLinkCards heading={item.heading} items={item.imageLinkCards} />
      )}
      {item.model.apiKey === 'internal_video_modular_block' && (
        <InternalVideo video={item.internalVideo} reduceGaps={reduceGaps} />
      )}
      {item.model.apiKey === 'locations_modular_block' && (
        <LocationCards items={item.locations} />
      )}
      {item.model.apiKey === 'logo_content_blocks_modular_block' && (
        <LogoContentBlocks
          heading={item.heading}
          items={item.logoContentBlocks}
        />
      )}
      {item.model.apiKey === 'tabs_modular_block' && (
        <Tabs heading={item.heading} items={item.tabs} />
      )}
      {item.model.apiKey === 'team_listing_modular_block' && (
        <TeamListing heading={item.heading} items={item.team} />
      )}
      {item.model.apiKey === 'video_player_modular_block' && (
        <VideoPlayer
          heading={item.heading}
          text={item.text}
          items={item.items}
        />
      )}
      {item.model.apiKey === 'visa_widget_modular_block' && <VisaWidget />}
    </React.Fragment>
  ));

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment AccordionModularBlockFragment on DatoCmsAccordionModularBlock {
    id
    model {
      apiKey
    }
    heading
    accordionItems {
      id
      heading
      text
    }
  }

  fragment AnchoredContentBlocksModularBlockFragment on DatoCmsAnchoredContentBlocksModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    anchoredContentBlocks {
      id
      anchorLinkText
      heading
      content
    }
  }

  fragment CtaStripModularBlockFragment on DatoCmsCtaStripModularBlock {
    id
    model {
      apiKey
    }
    heading
    link {
      ... on DatoCmsLink {
        text
        page {
          ...LinkFragment
        }
      }
      ... on DatoCmsExternalLink {
        text
        url
      }
    }
    version
  }

  fragment ContactCtaStripModularBlockFragment on DatoCmsContactCtaStripModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    phoneNumber
    emailAddress
  }

  fragment ContainedImageModularBlockFragment on DatoCmsContainedImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 1140)
      alt
    }
  }

  fragment ContentBlocksModularBlockFragment on DatoCmsContentBlocksModularBlock {
    id
    model {
      apiKey
    }
    heading
    contentBlocks {
      id
      heading
      text
    }
    link {
      text
      page {
        ...LinkFragment
      }
    }
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    twoColumns
    backgroundColour {
      hex
    }
  }

  fragment ContentFeaturedLinksImageLinkCardsModularBlockFragment on DatoCmsContentFeaturedLinksImageLinkCardsModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    link {
      text
      page {
        ...LinkFragment
      }
    }
    featuredLinksHeading
    featuredLinks {
      text
      page {
        ...LinkFragment
      }
    }
    imageLinkCards {
      ...ImageLinkCardsItemFragment
    }
  }

  fragment DiversityTravelAppModularBlockFragment on DatoCmsDiversityTravelAppModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    appStoreUrl
    googlePlayUrl
    travelItems {
      heading
      text
    }
    tempImage {
      gatsbyImageData
      alt
    }
  }

  fragment ExternalVideoModularBlockFragment on DatoCmsExternalVideoModularBlock {
    id
    model {
      apiKey
    }
    externalVideo {
      height
      width
      provider
      providerUid
      url
    }
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    heading
    logos {
      url
      alt
    }
  }

  fragment FeaturedResourcesModularBlockFragment on DatoCmsFeaturedResourcesModularBlock {
    id
    model {
      apiKey
    }
    heading
  }

  fragment FeaturedTeamModularBlockFragment on DatoCmsFeaturedTeamModularBlock {
    id
    model {
      apiKey
    }
    heading
    link {
      text
      page {
        ...LinkFragment
      }
    }
    team {
      ...TeamItemFragment
    }
  }

  fragment FeaturedTestimonialsModularBlockFragment on DatoCmsFeaturedTestimonialsModularBlock {
    id
    model {
      apiKey
    }
    heading
    testimonials {
      name
      quote
    }
  }

  fragment FormModularBlockFragment on DatoCmsFormModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    formName
  }

  fragment FullWidthImageModularBlockFragment on DatoCmsFullWidthImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 2000, layout: FULL_WIDTH)
      alt
    }
  }

  fragment IconContentBlocksModularBlockFragment on DatoCmsIconContentBlocksModularBlock {
    id
    model {
      apiKey
    }
    heading
    iconContentBlocks {
      id
      icon {
        url
        alt
      }
      text
    }
  }

  fragment ImageContentModularBlockFragment on DatoCmsImageContentModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 710, height: 800)
      alt
    }
    heading
    text
    featuredPoints {
      text
      colour {
        hex
      }
    }
    featuredLinks {
      text
      page {
        ...LinkFragment
      }
    }
    link {
      ... on DatoCmsLink {
        text
        page {
          ...LinkFragment
        }
      }
      ... on DatoCmsExternalLink {
        text
        url
      }
    }
    imageBlobMask
    blobColour {
      hex
    }
    flip
  }

  fragment ImageCtaCardsModularBlockFragment on DatoCmsImageCtaCardsModularBlock {
    id
    model {
      apiKey
    }
    heading
    imageCtaCards {
      ...ImageCtaCardsItemFragment
    }
  }

  fragment ImageGalleryModularBlockFragment on DatoCmsImageGalleryModularBlock {
    id
    model {
      apiKey
    }
    heading
    images {
      gatsbyImageData(width: 880, height: 700)
      alt
    }
  }

  fragment ImageLinkCardsModularBlockFragment on DatoCmsImageLinkCardsModularBlock {
    id
    model {
      apiKey
    }
    heading
    imageLinkCards {
      ...ImageLinkCardsItemFragment
    }
  }

  fragment InternalVideoModularBlockFragment on DatoCmsInternalVideoModularBlock {
    id
    model {
      apiKey
    }
    internalVideo {
      format
      url
      video {
        thumbnailUrl
        mp4Url
      }
    }
  }

  fragment LocationsModularBlockFragment on DatoCmsLocationsModularBlock {
    id
    model {
      apiKey
    }
    locations {
      id
      name
      address
      phoneNumber
    }
  }

  fragment LogoContentBlocksModularBlockFragment on DatoCmsLogoContentBlocksModularBlock {
    id
    model {
      apiKey
    }
    heading
    logoContentBlocks {
      id
      logo {
        url
        alt
      }
      text
    }
  }

  fragment TabsModularBlockFragment on DatoCmsTabsModularBlock {
    id
    model {
      apiKey
    }
    heading
    tabs {
      id
      tab
      heading
      text
    }
  }

  fragment TeamListingModularBlockFragment on DatoCmsTeamListingModularBlock {
    id
    model {
      apiKey
    }
    heading
    team {
      ...TeamItemFragment
    }
  }

  fragment VideoPlayerModularBlockFragment on DatoCmsVideoPlayerModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    items {
      heading
      icon {
        url
        alt
      }
      video {
        format
        url
        video {
          thumbnailUrl
          mp4Url
        }
      }
      timestamps {
        seconds
        description
      }
    }
  }

  fragment VisaWidgetModularBlockFragment on DatoCmsVisaWidgetModularBlock {
    id
    model {
      apiKey
    }
  }
`;
