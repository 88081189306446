import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  fontSize,
  brandFonts,
  brandColours,
  fontWeights,
  standardTransition,
  visuallyHidden,
  sectionMargins,
} from '../styles';
import { Container, Slider, BlobBackground } from './ui';

const StyledFeaturedTestimonials = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledHeading = styled.h2`
  ${visuallyHidden()}
`;

const StyledItems = styled(Slider)`
  padding: 0 40px;

  ${minBreakpointQuery.tiny`
    padding-right: 60px;
    padding-left: 60px;
  `}

  ${minBreakpointQuery.small`
    padding-right: 80px;
    padding-left: 80px;
  `}

  ${minBreakpointQuery.medium`
    padding-right: 100px;
    padding-left: 100px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 120px;
    padding-left: 120px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-right: 140px;
    padding-left: 140px;
  `}

  .slick-arrow {
    top: 50%;

    &.slick-prev {
      left: 0;
      transform: translate(-20px, -50%);
    }

    &.slick-next {
      right: 0;
      transform: translate(20px, -50%);
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-dots {
    margin-top: 25px;
    list-style-type: none;
    text-align: center;

    ${minBreakpointQuery.small`
      margin-top: 30px;
    `}

    ${minBreakpointQuery.large`
      margin-top: 35px;
    `}

    li {
      display: inline-block;
      margin: 0 6px;

      button {
        display: block;
        padding: 0;
        width: 12px;
        height: 12px;
        background-color: ${brandColours.primary};
        border: none;
        border-radius: 50%;
        text-indent: -9999em;
        transition: ${standardTransition('background-color')};

        ${minBreakpointQuery.small`
          width: 14px;
          height: 14px;
        `}

        ${minBreakpointQuery.large`
          width: 16px;
          height: 16px;
        `}
      }

      &.slick-active {
        button {
          background-color: ${brandColours.secondary};
        }
      }
    }
  }
`;

const StyledItem = styled.figure`
  margin: 0;
  text-align: center;
`;

const StyledBlockquote = styled.blockquote`
  margin: 0;
`;

const StyledQuote = styled.p`
  line-height: 1.7;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}

  &:before {
    content: '“';
    display: block;
    margin-bottom: 20px;
    height: 32px;
    color: ${brandColours.secondary};
    font-family: ${brandFonts.secondary};
    ${fontSize(120)};
    line-height: 0.8;

    ${minBreakpointQuery.small`
      margin-bottom: 25px;
      height: 44px;
      ${fontSize(160)};
    `}

    ${minBreakpointQuery.large`
      margin-bottom: 30px;
      height: 56px;
      ${fontSize(200)};
    `}
  }
`;

const StyledName = styled.figcaption`
  margin-top: 15px;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};

  ${minBreakpointQuery.small`
    margin-top: 20px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
    ${fontSize(20)};
  `}
`;

const FeaturedTestimonials = ({ heading, items }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    dots: true,
    dotsClass: 'slick-dots',
  };

  return (
    <StyledFeaturedTestimonials>
      <BlobBackground colour={brandColours.quaternary}>
        <Container>
          <StyledHeading>{heading}</StyledHeading>
          <StyledItems sliderOptions={sliderOptions}>
            {items.map(({ quote, name }, id) => (
              <StyledItem key={id}>
                <StyledBlockquote>
                  <StyledQuote>"{quote}"</StyledQuote>
                </StyledBlockquote>
                <StyledName>{name}</StyledName>
              </StyledItem>
            ))}
          </StyledItems>
        </Container>
      </BlobBackground>
    </StyledFeaturedTestimonials>
  );
};

export default FeaturedTestimonials;
